// Fonts
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
@import '~@fortawesome/fontawesome-free/css/all.css';
// OverlayScrollbars
@import '~overlayscrollbars/css/OverlayScrollbars.css';
// iCheck
@import '~icheck-bootstrap/icheck-bootstrap.css';
// AdminLTE
@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';


@import '~select2/dist/css/select2.css';
@import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css';
@import "/node_modules/flag-icons/css/flag-icons.min.css";
@import "flatpickr";

i.flag-icon {
    opacity: 0.9;
}

div.sidebar li.nav-header {
    text-transform: uppercase;
}

#modal-guest i.guest-item-icon  {
    width: 1.5em !important;
}

.ck-editor__editable_inline {
    min-height: 500px;
}

.ag-theme-alpine .ag-row.row-selected {
    background-color: #EEE !important;
}

.ag-theme-alpine .ag-header-cell {
    padding-left: 5px !important;
}

.ag-theme-alpine .ag-cell {
    padding-left: 5px !important;
}



.sidebar-collapse .brand-link .brand-image {
    margin-left:0px !important;
}

.sidebar-lvmh {
    background-color: #f2f1ed !important;
}

.small-box .icon i.flag-icon  {
    font-size:55px !important;
}

form label.error {
    color:red !important;
}

.bg-light-gray {
    background-color: #E7E7E7 !important;
}

